<template>
  <div>
    <div class="refusedBox" v-if="detail.status == 4">
      <div class="refusedLe">
        <div class="refusedTitle">拒绝原因</div>
        <div class="refusedContent">{{ detail.remarks }}</div>
      </div>
      <div class="refusedRi">
        <el-button type="primary" size="small" @click="handleAgainEntrust">修改委托信息</el-button>
      </div>
    </div>
    <div class="detailsTitle">
      <div class="entrust_no">委托编号：{{ detail.entrust_no | textFormat }}</div>
      <div class="status">
        <span class="statusText">状态：</span>
        <span v-if="detail.status == 0" style="color: #ee9900">待审核</span>
        <span v-if="detail.status == 1" style="color: #ee9900">待提交</span>
        <span v-if="detail.status == 2" style="color: #000000">已成单</span>
        <span v-if="detail.status == 3" style="color: #666666">已取消</span>
        <span v-if="detail.status == 4" style="color: red;">拒绝</span>
      </div>
    </div>
    <!--  上传委托-->
    <div v-if="type == 1" class="padding40">
      <uploadEntrust ref="uploadEntrust" :userType="1" :detail="detail" :status="status"></uploadEntrust>
    </div>
    <!--  在线委托-->
    <div v-if="type == 2" class="padding40">
      <lineEntrust :detail="detail" :status="status"></lineEntrust>
    </div>
<!--    海运费明细-->
    <div style="padding: 0px 40px">
      <freightDetail :detail="detail"></freightDetail>
    </div>
  </div>
</template>

<script>
import uploadEntrust from "~bac/components/dealManagement/myEntrust/uploadEntrust"
import lineEntrust from "~bac/components/dealManagement/myEntrust/lineEntrust"
import freightDetail from "~bac/components/dealManagement/myEntrust/freightDetail"

export default {
  name: "myEntrustDetails",
  components: { uploadEntrust, lineEntrust, freightDetail },
  data() {
    return {
      type: 1,   //1是上传委托，2是在线委托
      id: '',
      status: '',    //  0-待审核，1-待提交，2-已成单，3-已取消，4-拒接
      detail: {},
      loading: false
    }
  },
  mounted() {
    this.type = this.$route.query.type
    this.id = this.$route.query.id
    this.status = this.$route.query.status
    this.getDetail()
  },
  methods: {
    async getDetail() {
      let parmas = {
        id: this.id
      }
      this.loading = true
      let res = await this.$store.dispatch("baseConsole/getEntrustDetails", parmas)
      this.loading = false
      if (res.success) {
        this.detail = res.data
      }
    },
    handleAgainEntrust() {
      let activeName = ''
      if (this.type == 1) {
        activeName = 'first'
      }
      if (this.type == 2) {
        activeName = 'second'
      }
      this.$router.push({
        path: '/bookingSpace',
        query: {
          detail: this.detail,
          activeName,
          id: this.$route.query.id,
        }
      })
    }
  },
}
</script>

<style scoped lang="less">
.refusedBox {
  display: flex;
  justify-content: space-between;
  padding: 20px;
  background: #FFFFFF;
  align-items: center;

  .refusedTitle {
    font-size: 20px;
    font-weight: 800;
  }

  .refusedContent {
    font-size: 18px;
    margin-top: 10px;
  }
}

.detailsTitle {
  display: flex;
  justify-content: space-between;
  padding: 20px;
  background: #EBEEF5;

  .entrust_no {
    color: #000000;
    font-size: 20px;
    font-weight: 800;
  }

  .status {
    font-weight: 800;
    font-size: 18px;

    .statusText {
      color: #000000;
    }
  }

}
.padding40{
  padding: 40px;
}
</style>