<template>
  <div>
    <el-form
        label-width="100px"
        ref="form"
        :model="form"
        :rules="rules"
        label-position="left"
        :loanding="loading"
        :disabled="status == 3 || status == 4 || status == 1"
    >
      <el-row>
        <el-col :span="12">
          <el-form-item label="起运港" prop="from_port">
            <portSelect
                v-model="form.from_port"
                placeTitle="请输入起运港"
                clearable
                @input="from_port_input"
            />
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="目的港" prop="dest_port">
            <portSelect
                v-model="form.dest_port"
                placeTitle="请输入目的港"
                clearable
                @input="dest_port_input"
            />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="12">
          <el-form-item label="预配船期" prop="preplan_shipment">
            <el-date-picker
                v-model="form.preplan_shipment"
                type="date"
                placeholder="请选择日期"
                size="mini"
                class="from_input"
            >
            </el-date-picker>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="预配船公司" prop="preplan_shipping_company">
            <shipCompanySearch size="mini" class="from_input" v-model="form.preplan_shipping_company" :modelVlaue="'code'"></shipCompanySearch>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="4">
          <el-form-item label="是否整箱" prop="is_whole_case">
            <el-checkbox v-model="form.is_whole_case" :true-label="1" :false-label="0"></el-checkbox>
          </el-form-item>
        </el-col>
        <el-col :span="20" v-if="form.is_whole_case">
          <el-form-item label="整箱请选箱型：" prop="price_lcl_general" label-width="120px">
            <el-checkbox v-model="form.fcl1" :true-label="1" :false-label="0">20'GP</el-checkbox>
            <el-checkbox v-model="form.fcl2" :true-label="1" :false-label="0">40'GP</el-checkbox>
            <el-checkbox v-model="form.fcl3" :true-label="1" :false-label="0">40'HC</el-checkbox>
            <el-checkbox v-model="form.fcl4" :true-label="1" :false-label="0">45'GP</el-checkbox>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="12">
          <el-form-item label="件数" prop="amount">
            <el-input placeholder="请输入件数" v-model="form.amount" class="from_input from_input_variation" size="mini">
              <template slot="append">
                <el-select v-model="form.amount_unit" style="width: 110px">
                  <el-option
                      v-for="item in numberUnitList"
                      :key="item.id"
                      :label="item.name_zh"
                      :value="item.id">
                  </el-option>
                </el-select>
              </template>
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="毛重" prop="gross_weight">
            <el-input placeholder="请输入毛重" v-model="form.gross_weight" class="from_input from_input_variation" size="mini">
              <template slot="append">KGS</template>
            </el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="12">
          <el-form-item label="体积" prop="volume" >
            <el-input placeholder="请输入体积" v-model="form.volume" class="from_input from_input_variation" size="mini">
              <template slot="append">CBM</template>
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="UN NO." prop="un_no">
            <el-input placeholder="请输入UN NO." v-model="form.un_no" style="width: 170px" size="mini">
            </el-input>
            <span class="unNo">根据最新规定，危险品货物必需提供UNNO和CAS NO</span>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="12">
          <el-form-item label="品名" prop="cargo_name">
            <el-input
                class="from_input"
                type="textarea"
                placeholder="请输入品名"
                rows="4"
                v-model="form.cargo_name"
                maxlength="256"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="标志及号码" prop="mark_numbers">
            <el-input
                class="from_input"
                type="textarea"
                placeholder="请输入Marks & numbers信息"
                rows="4"
                v-model="form.mark_numbers"
                maxlength="256"
            ></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="12">
          <el-form-item label="运输条款" prop="shipping_terms">
            <el-select  v-model="form.shipping_terms" placeholder="请选择运输条款" size="mini"  class="from_input">
              <el-option
                  v-for="item in shippingTermsList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="运费条款" prop="fee_terms">
            <el-select  v-model="form.fee_terms"  placeholder="请选择运费条款" size="mini"  class="from_input">
              <el-option
                  v-for="item in feeTermsList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
  </div>
</template>

<script>
import portSelect from "~ymp/components/common/portSelect"
import shipCompanySearch from '~ymp/components/common/shipCompanySearch'
export default {
  name: "basicInfo",
  components: { portSelect, shipCompanySearch },
  props: {
    detail: {
      type: Object,
      default: () => {
        return {}
      }
    },
    status: {
      type: String | Number,
      default: 0
    }
  },
  data() {
    return {
      form: {
        from_port: null,
        dest_port: null,
        preplan_shipment: null, //预配船期
        preplan_shipping_company: null, // 预配船公司
        is_whole_case: 0, // 是否整箱
        fcl1: 0,
        fcl2: 0,
        fcl3: 0,
        fcl4: 0,
        amount: '', //件数
        amount_unit: 1, //件数单位
        gross_weight: '', //毛重
        volume: '', //体积
        un_no: '',
        cargo_name: '',  //品名,
        mark_numbers: '',  //标志和号码
        shipping_terms: '',  //运输条款
        fee_terms: '',  //运费条款
      },
      numberUnitList: [],  //件数单位列表
      shippingTermsList: [],   // 运输条款列表
      feeTermsList: [],   // 运费条款
      rules: {
        from_port: [{ required: true, message: " ", trigger: "change" }],
        dest_port: [{ required: true, message: " ", trigger: "change" }],
        is_whole_case: [{ required: true, message: " ", trigger: "change" }],
        amount: [{ required: true, message: " ", trigger: "change" }],
        gross_weight: [{ required: true, message: " ", trigger: "change" }],
        volume: [{ required: true, message: " ", trigger: "change" }],
        shipping_terms: [{ required: true, message: " ", trigger: "change" }],
        fee_terms: [{ required: true, message: " ", trigger: "change" }],

      },
      queryInfo: {
        from_port: null,
        from_port_id: null,
        dest_port: null,
        dest_port_id: null,
      },
      loading: false
    }
  },
  mounted() {
    this.initList()
    if (this.$route.query && this.$route.query.info) {
      this.queryInfo = Object.assign({}, this.$route.query.info)
      this.form.from_port =this.queryInfo.from_port_name_en
      this.form.dest_port =this.queryInfo.dest_port_name_en
    }
  },
  methods: {
    from_port_input(val) {
      if (val === '') val = null
      let num = Number(val)
      if (!isNaN(num) || val === '') {
        this.queryInfo.from_port_id = val
      }
    },
    dest_port_input(val) {
      if (val === '') val = null
      let num = Number(val)
      if (!isNaN(num) || val === '') {
        this.queryInfo.dest_port_id = val
      }
    },
    async initList(){
      this.loading = true
      let res = await Promise.all([
        this.$store.dispatch("API_bookingSpace/getBookingpacKingType"),
        this.$store.dispatch("API_bookingSpace/getBookingShippingTerms",),
        this.$store.dispatch("API_bookingSpace/getBookingFeeTerms"),
      ])
      if (res[0].success) {
        this.numberUnitList = res[0].data
      }
      if (res[1].success) {
        this.shippingTermsList = res[1].data
      }
      if (res[2].success) {
        this.feeTermsList = res[2].data
      }
    },
    async saveFn() {
      if (this.form.is_whole_case == 1 &&
          this.form. fcl1 == 0 &&
          this.form. fcl2 == 0 &&
          this.form. fcl3 == 0 &&
          this.form. fcl4 == 0
      ) {
        this.$message.error('整箱状态下，至少选择一种箱型')
        return
      }
     return new Promise((resolve, reject) => {
       this.$refs["form"].validate((valid) => {
         if (valid) {
           let parmas = {
             from_port: this.queryInfo.from_port_id,
             dest_port: this.queryInfo.dest_port_id,
             preplan_shipment: this.$moment(this.form.preplan_shipment).unix(),
             preplan_shipping_company: this.form.preplan_shipping_company,
             is_whole_case: this.form.is_whole_case,
             amount: this.form.amount,
             amount_unit: this.form.amount_unit,
             gross_weight: this.form.gross_weight,
             volume: this.form.volume,
             un_no: this.form.un_no,
             cargo_name: this.form.cargo_name,
             mark_numbers: this.form.mark_numbers,
             shipping_terms: this.form.shipping_terms,
             fee_terms: this.form.fee_terms,
           }
           if (this.form.is_whole_case == 1) {
             let whole_box = []
             if (this.form.fcl1 == 1) {
               whole_box.push(1)
             }
             if (this.form.fcl2 == 1) {
               whole_box.push(2)
             }
             if (this.form.fcl3 == 1) {
               whole_box.push(3)
             }
             if (this.fcl4 == 1) {
               whole_box.push(4)
             }
             parmas.whole_box = whole_box.join(',')
           };
           resolve(parmas)
         } else {
           resolve(false)
         }
       });
      })
    },
    initInfo() {
      this.form.from_port = this.detail.from_port_name_en
      this.form.dest_port = this.detail.dest_port_name_en
      this.queryInfo.from_port_id = this.detail.from_port
      this.queryInfo.dest_port_id = this.detail.dest_port
      if (this.detail.preplan_shipment ) {
        this.form.preplan_shipment = this.$moment.unix(this.detail.preplan_shipment)
      }
      this.form.preplan_shipping_company = this.detail.preplan_shipping_company
      this.form.is_whole_case = this.detail.is_whole_case
      if (this.form.is_whole_case == 1) {
        let whole_box = this.detail.whole_box
        if (whole_box.indexOf('1') !== -1) {
          this.form.fcl1 = 1
        } else {
          this.form.fcl1 = 0
        }
        if (whole_box.indexOf('2') !== -1) {
          this.form.fcl2 = 1
        } else {
          this.form.fcl2 = 0
        }
        if (whole_box.indexOf('3') !== -1) {
          this.form.fcl3 = 1
        } else {
          this.form.fcl3 = 0
        }
        if (whole_box.indexOf('4') !== -1) {
          this.form.fcl4 = 1
        } else {
          this.form.fcl4 = 0
        }
      }
      this.form.amount = this.detail.amount
      this.form.amount_unit = this.detail.amount_unit
      this.form.gross_weight = this.detail.gross_weight
      this.form.volume = this.detail.volume
      this.form.un_no = this.detail.un_no
      this.form.cargo_name = this.detail.cargo_name
      this.form.mark_numbers = this.detail.mark_numbers
      this.form.shipping_terms = this.detail.shipping_terms
      this.form.fee_terms = this.detail.fee_terms
    }
  },
  watch: {
    detail: {
      deep: true,
      handler: function (newV, oldV) {
        this.initInfo()
      }
    }
  }
}
</script>

<style scoped lang="less">
.from_input {
  width: 274px;
}
.from_input_variation {
  margin-top: 6px;
}
.unNo {
  margin-left: 8px;
  color: #FFBC31;
}
</style>