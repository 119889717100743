<template>
  <div>
    <div class="tableContainer">
      <div class="titleBox">
        <div class="titleLine"></div>
        订舱资料
      </div>
      <div class="content">
        <uploadEntrust ref="uploadEntrust" :userType="2" :detail="detail" :status="status"></uploadEntrust>
      </div>
    </div>
    <div class="tableContainer">
      <div class="titleBox">
        <div class="titleLine"></div>
        基本信息
      </div>
      <div class="content">
        <basicInfo ref="basicInfo" :detail="detail" :status="status"></basicInfo>
      </div>
    </div>
    <div class="tableContainer">
      <div class="titleBox">
        <div class="titleLine"></div>
        地址信息
      </div>
      <div class="content">
        <uploadAdress ref="uploadAdress" :detail="detail" :status="status"></uploadAdress>
      </div>
    </div>
    <div class="emptyBox"></div>
    <el-button type="primary" size="small" @click="handleSubmit" v-if="status == 0" :loading="submitLoading">更新数据
    </el-button>
    <el-button size="small" @click="handleCancel" v-if="status == 0" :loading="submitLoading">取消委托</el-button>
    <el-button type="primary" size="small" @click="handleAgainEntrust" v-if="status == 3" :loading="submitLoading">重新委托
    </el-button>
  </div>
</template>

<script>
import uploadEntrust from "./uploadEntrust"
import basicInfo from "./uploadEntrustSon/basicInfo"
import uploadAdress from "./uploadEntrustSon/uploadAdress"

export default {
  name: "lineEntrust",
  props: {
    detail: {
      type: Object,
      default: () => {
        return {}
      }
    },
    status: {
      type: String | Number,
      default: 1
    }
  },
  components: { uploadEntrust, basicInfo, uploadAdress },
  data() {
    return {
      submitLoading: false
    }
  },
  methods: {
    async handleSubmit() {
      let uploadEntrust = await this.$refs.uploadEntrust.saveFn()
      let basicInfo = await this.$refs.basicInfo.saveFn()
      let uploadAdress = await this.$refs.uploadAdress.saveFn()
      if (uploadEntrust && basicInfo && uploadAdress) {
        let parmas = Object.assign(uploadEntrust, basicInfo, uploadAdress)
        parmas.id = this.$route.query.id,
        parmas.entrust_type = 2
        this.submitLoading = true
        let res = await this.$store.dispatch("API_bookingSpace/uploadEntrustSave", parmas);
        if (res.success) {
          this.submitLoading = false
          this.$router.push({
            path: '/bookingSpaceTitle',
            query: {
              id: res.data,
              type: 2,
              status: 0
            }
          })
        }
      }
    },
    handleCancel() {
      this.$confirm('是否确定取消委托！', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        let parmas = {
          id: this.$route.query.id,
          status: 3
        }
        let res = await this.$store.dispatch("API_bookingSpace/uploadEntrustSave", parmas);
        if (res.success) {
          this.$message.success('操作成功')
          this.$router.push('/console/myEntrust/list')
        }
      }).catch(() => {
      });
    },
    // 重新委托
    handleAgainEntrust() {
      this.$router.push({
        path: '/bookingSpace',
        query: {
          detail: this.detail,
          activeName: 'second',
        }
      })
    }
  }
}
</script>

<style scoped lang="less">
.tableContainer {
  width: 100%;
  background: #FFFFFF;
  box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.19);
  margin-top: 20px;

  .content {
    padding: 32px;
  }
}

.titleBox {
  position: relative;
  width: 100%;
  height: 54px;
  line-height: 54px;
  border-bottom: 1px #C0C4CC solid;
  padding-left: 32px;

  .titleLine {
    width: 2px;
    height: 16px;
    background: #22D00E;
    position: absolute;
    left: 0;
    top: 19px;
  }
}

.submitBtn {
  background-color: #91BE42;
  border-color: #91BE42;
  margin-top: 32px;
}
.emptyBox {
  height: 20px;
  width: 100%;
}
</style>