<template>
  <div class="freightDetail">
    <div class="detailTitle">海运费用明细 <span class="titleHint">以平台最新运价费用为准，报价费用仅供参考</span></div>
    <el-table
        :data="tableData"
        class="tableData"
        :height="500"
        :tableLoading="loading"
        v-if="tableIsShow"
    >
      <el-table-column label="币种" :show-overflow-tooltip="true" prop="currency">
      </el-table-column>
      <el-table-column label="费用类型" :show-overflow-tooltip="true" prop="fee_type">
      </el-table-column>
      <el-table-column label="费用名称" :show-overflow-tooltip="true" prop="fee_name">
      </el-table-column>
      <el-table-column label="单位" :show-overflow-tooltip="true" prop="measure_unit">
      </el-table-column>
      <el-table-column label="数量" :show-overflow-tooltip="true" prop="count">
      </el-table-column>
      <el-table-column label="单价" :show-overflow-tooltip="true">
        <div slot-scope="scope">
          {{ scope.row.price }}
          <span v-if="scope.row.up_price > 0" style="color: red">(+{{ scope.row.up_price }})</span>
          <span v-if="scope.row.up_price < 0" style="color: #0dad6d">(-{{ scope.row.up_price }})</span>
          <span v-if="scope.row.price_lowest">
            , min. {{ scope.row.price_lowest }}
          </span>
        </div>
      </el-table-column>
      <el-table-column label="备注" prop="remarks">
        <div slot-scope="scope">
          <div class="textOverflow" :title="scope.row.remarks | textFormat">{{ scope.row.remarks | textFormat }}</div>
        </div>
      </el-table-column>
    </el-table>
    <div v-else class="tableNoShow">
      委托信息不全，暂无法获取费用明细
    </div>
  </div>
</template>

<script>
export default {
  name: "freightDetail",
  props: {
    detail: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data() {
    return {
      tableData: [],
      loading: false,
      tableIsShow: false
    }
  },
  watch: {
    detail: {
      deep: true,
      handler: function (newV, oldV) {
        this.getList()
      }
    }
  },
  methods: {
    async getList() {
      if (
          this.$route.query.type == 2 &&
          this.detail.from_port &&
          this.detail.dest_port &&
          this.detail.amount &&
          this.detail.gross_weight &&
          this.detail.volume &&
          this.detail.into_storage_fee_way
      ) {
        this.tableIsShow = true
        let parmas = {
          from_port: this.detail.from_port,
          dest_port: this.detail.dest_port,
          entrust_kind: 2,
          quotation_no: this.detail.quotation_no,
          amount: this.detail.amount,
          gross_weight: this.detail.gross_weight,
          volume: this.detail.volume,
          into_storage_fee_way: this.detail.into_storage_fee_way,
          cargo_type: 1,
        }
        this.loading = true
        let res = await this.$store.dispatch("baseConsole/getEntrustFeeList", parmas);
        this.loading = false
        if (res.success) {
          this.tableData = res.data
        }
      } else {
        this.tableIsShow = false
      }
    }
  }
}
</script>

<style scoped lang="less">
.freightDetail {
  padding: 20px;
  box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.19);
  .detailTitle {
    font-size: 20px;
    font-weight: 800;
  }
  .titleHint {
    font-size: 16px;
    color: red;
    margin-left: 40px;
    font-weight: 400;
  }
}
.tableData {
  width: 100%;
  margin-top: 24px;
}
.tableNoShow {
  margin-top: 24px;
}
</style>