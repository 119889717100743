<template>
  <div>
    <el-form
        ref="form"
        :model="form"
        :rules="rules"
        label-position="left"
        style="width: 100%"
        size="mini"
        :disabled="status == 3 || status == 4 || status == 1"
    >
      <div class="uploadContiner">
        <div class="uploadItem">
          <div class="uploadTitle">
            <div>发货人 Shipper</div>
            <div class="selectAdress" @click="handleAdress(1)">
              <i class="el-icon-location-outline"></i>选择地址
            </div>
          </div>
          <el-form-item prop="name1" style="margin-top: 18px">
            <el-input v-model="form.name1" size="mini" placeholder="请填写姓名" @input="changeForm(1)"></el-input>
          </el-form-item>
          <el-form-item prop="price_lcl_general">
            <el-input
                type="textarea"
                placeholder="请填写地址"
                rows="4"
                v-model="form.adress1"
                maxlength="256"
                @input="changeForm(1)"
            ></el-input>
          </el-form-item>
          <el-form-item prop="phone1">
            <el-input v-model="form.phone1" size="mini" placeholder="请填写电话及传真" @input="changeForm(1)"></el-input>
          </el-form-item>
          <el-form-item prop="email1">
            <el-input v-model="form.email1" size="mini" placeholder="请填写邮箱" @input="changeForm(1)"></el-input>
          </el-form-item>
        </div>
        <div class="uploadItem">
          <div class="uploadTitle">
            <div>收货人 Consignee</div>
            <div class="selectAdress" @click="handleAdress(2)">
              <i class="el-icon-location-outline"></i>选择地址
            </div>
          </div>
          <el-form-item prop="name2" style="margin-top: 18px">
            <el-input v-model="form.name2" size="mini" placeholder="请填写姓名" @input="changeForm(2)"></el-input>
          </el-form-item>
          <el-form-item prop="price_lcl_general">
            <el-input
                type="textarea"
                placeholder="请填写地址"
                rows="4"
                v-model="form.adress2"
                maxlength="256"
                @input="changeForm(2)"
            ></el-input>
          </el-form-item>
          <el-form-item prop="phone1">
            <el-input v-model="form.phone2" size="mini" placeholder="请填写电话及传真" @input="changeForm(2)"></el-input>
          </el-form-item>
          <el-form-item prop="email1">
            <el-input v-model="form.email2" size="mini" placeholder="请填写邮箱" @input="changeForm(2)"></el-input>
          </el-form-item>
        </div>
        <div class="uploadItem">
          <div class="uploadTitle">
            <div>通知人 Notify Party</div>
            <div class="selectAdress" @click="handleAdress(3)">
              <i class="el-icon-location-outline"></i>选择地址
            </div>
          </div>
          <el-form-item prop="name3" style="margin-top: 18px">
            <el-input v-model="form.name3" size="mini" placeholder="请填写姓名" @input="changeForm(3)"></el-input>
          </el-form-item>
          <el-form-item prop="price_lcl_general">
            <el-input
                type="textarea"
                placeholder="请填写地址"
                rows="4"
                v-model="form.adress3"
                maxlength="256"
                @input="changeForm(3)"
            ></el-input>
          </el-form-item>
          <el-form-item prop="phone1">
            <el-input v-model="form.phone3" size="mini" placeholder="请填写电话及传真" @input="changeForm(3)"></el-input>
          </el-form-item>
          <el-form-item prop="email1">
            <el-input v-model="form.email3" size="mini" placeholder="请填写邮箱" @input="changeForm(3)"></el-input>
          </el-form-item>
        </div>
      </div>
    </el-form>
    <el-dialog
        title="选择地址"
        :visible.sync="adressDialog"
        width="800px"
        append-to-body
    >
      <adressDialog v-if="adressDialog"  @selectAdress="selectAdress" @closeAdress="closeAdress" :adressIndex="adressIndex"></adressDialog>
    </el-dialog>
  </div>
</template>

<script>
import adressDialog from "~ymp/components/bookingSpace/uploadEntrustSon/adressDialog";
export default {
  name: "uploadAdress",
  props: {
    detail: {
      type: Object,
      default: () => {
        return {}
      }
    },
    status: {
      type: String | Number,
      default: 0
    }
  },
  components: { adressDialog },
  data() {
    return {
      form: {
        id1: '',
        name1: '',
        adress1: '',
        phone1: '',
        email1: '',

        id2: '',
        name2: '',
        adress2: '',
        phone2: '',
        email2: '',

        id3: '',
        name3: '',
        adress3: '',
        phone3: '',
        email3: '',
      },
      rules: {
        name1:  { required: true, message: "请输入姓名", trigger: 'change'},
        name2:  { required: true, message: "请输入姓名", trigger: 'change'},
        name3:  { required: true, message: "请输入姓名", trigger: 'change'},
      },
      adressDialog: false,
      adressIndex: 1
    }
  },
  methods: {
    handleAdress(val) {
      if (this.status == 1 || this.status == 3 || this.status == 4) { return }
      this.adressIndex = val
      this.adressDialog = true
    },
    selectAdress(row) {
      this.form[`id${this.adressIndex}`] = row.id
      this.form[`name${this.adressIndex}`] = row.name
      this.form[`adress${this.adressIndex}`] = row.address
      this.form[`phone${this.adressIndex}`] = row.telephone
      this.form[`email${this.adressIndex}`] = row.email
      this.adressDialog = false
    },
    closeAdress() {
      this.adressDialog = false
    },
    changeForm(index) {
      this.form[`id${index}`] = ''
    },
    // 在线提交
    async saveFn() {
      return new Promise((resolve, reject) => {
        this.$refs["form"].validate((valid) => {
          if (valid) {
            let obj1 = {
              address_type: 'shipper',
              name: this.form.name1,
              address: this.form.adress1,
              email: this.form.email1,
              telephone: this.form.phone1,
            }
            if (this.form.id1) { obj1.address_id = this.form.id1 }
            let obj2 = {
              address_type: 'consignee',
              name: this.form.name2,
              address: this.form.adress2,
              email: this.form.email2,
              telephone: this.form.phone2,
            }
            if (this.form.id2) { obj2.address_id = this.form.id2 }
            let obj3 = {
              address_type: 'notify_party',
              name: this.form.name3,
              address: this.form.adress3,
              email: this.form.email3,
              telephone: this.form.phone3,
            }
            if (this.form.id3) { obj3.address_id = this.form.id3 }
            let parmas = { address_info: JSON.stringify([obj1, obj2, obj3]) }
            resolve(parmas)
          } else {
            resolve(false)
          }
        });
      })
    },
    initInfo() {
      if (this.detail.shipper && this.detail.shipper.id) {
        this.form.id1 = this.detail.shipper.id
        this.form.name1 = this.detail.shipper.name
        this.form.adress1 = this.detail.shipper.address
        this.form.phone1 = this.detail.shipper.telephone
        this.form.email1 = this.detail.shipper.email
      }
      if (this.detail.consignee && this.detail.consignee.id) {
        this.form.id2 = this.detail.consignee.id
        this.form.name2 = this.detail.consignee.name
        this.form.adress2 = this.detail.consignee.address
        this.form.phone2 = this.detail.consignee.telephone
        this.form.email2 = this.detail.consignee.email
      }
      if (this.detail.notify_party && this.detail.notify_party.id) {
        this.form.id3 = this.detail.notify_party.id
        this.form.name3 = this.detail.notify_party.name
        this.form.adress3 = this.detail.notify_party.address
        this.form.phone3 = this.detail.notify_party.telephone
        this.form.email3 = this.detail.notify_party.email
      }
    }
  },
  watch: {
    detail: {
      deep: true,
      handler: function (newV, oldV) {
        this.initInfo()
      }
    }
  }
}
</script>

<style scoped lang="less">
.uploadContiner {
  width: 100%;
  display: flex;
  justify-content: space-between;

  .uploadItem {
    width: 32%;

    .uploadTitle {
      display: flex;
      justify-content: space-between;
      line-height: 32px;
      .selectAdress {
        width: 100px;
        height: 32px;
        background: #FFFFFF;
        border-radius: 4px;
        border: 1px solid #91BE42;
        color: #91BE42;
        cursor: pointer;
        line-height: 32px;
        text-align: center;
      }
    }
  }
}

</style>