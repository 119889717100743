<template>
  <div class="uploadEntrustContainer">
    <el-form
        label-width="200px"
        ref="form"
        :model="form"
        :rules="rules"
        label-position="left"
        style="padding-bottom: 20px"
        :disabled="status == 3 || status == 4 || status == 1"
    >
      <el-form-item label="海运出口委托书" prop="file1" v-if="userType == 1">
        <div class="uploadBox">
          <div class="fileName textOverflow">
            <span v-if="form.file1 && form.file1.name" :title="form.file1.name" class="color298DF8 point"
                  @click="handleFile(form.file1.url)">{{ form.file1.name }}</span>
            <span v-else class="color606266">请上传文件</span>
          </div>
          <fileUpload
              ref="uploadComponents1"
              @selectFile="uploadFile($event,1)"
              @uploadCompleteOSS="uploadCompleteOSS($event, 1)"
          >
            <div class="uploadBtn">本地上传</div>
          </fileUpload>
          <div class="uploadBtn2" @click="handleDtatBank(1)">资料库选择</div>
        </div>
      </el-form-item>
      <el-form-item prop="file2">
         <span slot="label" >
           MSDS
           <span style="color: red">（特殊货物必填）</span>
         </span>
        <div class="uploadBox">
          <div class="fileName textOverflow">
            <span v-if="form.file2 && form.file2.name" :title="form.file2.name" class="color298DF8 point"
                  @click="handleFile(form.file2.url)">{{ form.file2.name }}</span>
            <span v-else class="color606266">请上传文件</span>
          </div>
          <fileUpload
              ref="uploadComponents2"
              @selectFile="uploadFile($event,2)"
              @uploadCompleteOSS="uploadCompleteOSS($event, 2)"
          >
            <div class="uploadBtn">本地上传</div>
          </fileUpload>
          <div class="uploadBtn2" @click="handleDtatBank(2)">资料库选择</div>
        </div>
      </el-form-item>
      <el-form-item prop="file3">
          <span slot="label" >
           危包证
           <span style="color: red">（特殊货物必填）</span>
         </span>
        <div class="uploadBox">
          <div class="fileName textOverflow">
            <span v-if="form.file3 && form.file3.name" :title="form.file3.name" class="color298DF8 point"
                  @click="handleFile(form.file3.url)">{{ form.file3.name }}</span>
            <span v-else class="color606266">请上传文件</span>
          </div>
          <fileUpload
              ref="uploadComponents3"
              @selectFile="uploadFile($event,3)"
              @uploadCompleteOSS="uploadCompleteOSS($event, 3)"
          >
            <div class="uploadBtn">本地上传</div>
          </fileUpload>
          <div class="uploadBtn2" @click="handleDtatBank(3)">资料库选择</div>
        </div>
      </el-form-item>
      <el-form-item label="CAS NO." prop="cas_no">
        <div class="uploadBox">
          <el-input
              style="width: 200px"
              placeholder="请输入CSNO"
              type="text"
              v-model="form.cas_no"
              size="mini"
              @blur="casnoBlur"
          ></el-input>
          <div class="CSNO point" @click="handleHelp"><i class="el-icon-question" style="margin-right: 10px"></i>如何查看CAS NO.信息</div>
        </div>
      </el-form-item>
      <el-form-item label="商检报告" prop="file4">
        <div class="uploadBox">
          <div class="fileName textOverflow">
            <span v-if="form.file4 && form.file4.name" :title="form.file4.name" class="color298DF8 point"
                  @click="handleFile(form.file4.url)">{{ form.file4.name }}</span>
            <span v-else class="color606266">{{ commodityText }}</span>
          </div>
          <fileUpload
              ref="uploadComponents4"
              @selectFile="uploadFile($event,4)"
              @uploadCompleteOSS="uploadCompleteOSS($event, 4)"
          >
            <div class="uploadBtn">本地上传</div>
          </fileUpload>
          <div class="uploadBtn2" @click="handleDtatBank(4)">资料库选择</div>
        </div>
      </el-form-item>
      <el-form-item label="是否大件货" prop="is_big">
        <el-checkbox v-model="form.is_big" :true-label="1" :false-label="0">大件货物</el-checkbox>
        <span style="color: #606266;">（如果货物尺寸长宽高超过1.1M*1.1M*1.1M，请勾选，以便合理安排）</span>
      </el-form-item>
      <el-form-item label="进仓费收取方式" prop="into_storage_fee_way">
        <el-select class="typeSelect" v-model="form.into_storage_fee_way" placeholder="请选择" size="mini">
          <el-option v-for="item in intoStorageFeeWayList" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="报价编号" prop="quotation_no">
        <div class="uploadBox">
          <el-input
              style="width: 200px"
              placeholder="报价编号/询价编号"
              type="text"
              v-model="form.quotation_no"
              size="mini"
          ></el-input>
          <div class="color606266">（报价中的港口信息需与委托书中相同。拼箱海运费用以实时运价为准。）</div>
        </div>
      </el-form-item>
    </el-form>
    <el-button type="primary" size="small" @click="handleSubmit" v-if="userType == 1 && status == 0" :loading="submitLoading">更新数据
    </el-button>
    <el-button size="small" @click="handleCancel" v-if="userType == 1 && status == 0" :loading="submitLoading">取消委托</el-button>
    <el-button type="primary" size="small" @click="handleAgainEntrust" v-if="userType == 1 && status == 3" :loading="submitLoading">重新委托
    </el-button>
    <el-dialog
        title="资料库"
        :visible.sync="dataBankDialog"
        width="800px"
        append-to-body
    >
      <selectDataBank
          v-if="dataBankDialog"
          :dataBankType="dataBankType"
          @uploadDataBank="uploadDataBank"
          @closeUploadDataBank="closeUploadDataBank"
      ></selectDataBank>
    </el-dialog>
  </div>
</template>

<script>
import fileUpload from "~/baseComponents/fileUpload";
import selectDataBank from "~bac/components/dealManagement/myEntrust/uploadEntrustSon/selectDataBank";
import {getFileType} from "~/baseUtils";

export default {
  name: "uploadEntrust",
  components: {fileUpload, selectDataBank},
  props: {
    // 1的话，是控制台上传委托书在使用，  2的话，是在线委托使用
    userType: {
      type: String | Number,
      default: 1
    },
    detail: {
      type: Object,
      default: () => {
        return {}
      }
    },
    status: {
      type: String | Number,
      default: 0
    }
  },
  data() {
    return {
      form: {
        cas_no: '',
        is_big: 0,  // 是否是大件货
        into_storage_fee_way: 1,  // 进仓费收取方式
        quotation_no: '',  // 报价编号
        file1: null,
        file2: null,
        file3: null,
        file4: null,
      },
      commodityText: '输入CAS NO.信息后可知是否需上传',
      rules: {
        file1: {required: true, message: '请上传海运出口委托书'},
        quotation_no: {
          validator: async (rule, value, callback) => {
            if (value == "") {
              callback();
            } else {
              let parmas = {quotation_no: value}
              let res = await this.$store.dispatch('API_bookingSpace/bookingQuotationNoCheck', parmas);
              if (res.success) {
                callback();
              } else if (res.errorcode == '1025') {
                callback(new Error('报价编号不存在'));
              } else if (res.errorcode == '1026') {
                callback(new Error('由于报价中目的港与委托不符，报价数据无效'));
              }
            }
          },
          trigger: "change",
        },
      },
      intoStorageFeeWayList: [
        {
          value: 1,
          label: '司机支付(司机送货时直接付给仓库)'
        },
        {
          value: 2,
          label: '客户支付(客户支付给玉漭)'
        }
      ],
      dataBankDialog: false,  //  资料库选择
      dataBankType: 1,  // 资料库Type
      submitLoading: false,
    }
  },
  mounted() {

  },
  methods: {
    initInfo() {
      this.form.cas_no = this.detail.cas_no
      this.form.is_big = this.detail.is_big
      this.form.into_storage_fee_way = this.detail.into_storage_fee_way
      this.form.quotation_no = this.detail.quotation_no
      if (this.detail.attorney && this.detail.attorney.id) {
        let file1 = {
          attachment_id: this.detail.attorney.id,
          name: this.detail.attorney.name,
          format: this.detail.attorney.format,
          size: this.detail.attorney.size,
          url: this.detail.attorney.url,
          file_type: 1
        }
        this.form.file1 = file1
      }
      if (this.detail.msds && this.detail.msds.id) {
        let file2 = {
          attachment_id: this.detail.msds.id,
          name: this.detail.msds.name,
          format: this.detail.msds.format,
          size: this.detail.msds.size,
          url: this.detail.msds.url,
          file_type: 2
        }
        this.form.file2 = file2
      }
      if (this.detail.dangerous_package_license && this.detail.dangerous_package_license.id) {
        let file3 = {
          attachment_id: this.detail.dangerous_package_license.id,
          name: this.detail.dangerous_package_license.name,
          format: this.detail.dangerous_package_license.format,
          size: this.detail.dangerous_package_license.size,
          url: this.detail.dangerous_package_license.url,
          file_type: 3
        }
        this.form.file3 = file3
      }
      if (this.detail.survey_report && this.detail.survey_report.id) {
        let file4 = {
          attachment_id: this.detail.survey_report.id,
          name: this.detail.survey_report.name,
          format: this.detail.survey_report.format,
          size: this.detail.survey_report.size,
          url: this.detail.survey_report.url,
          file_type: 4
        }
        this.form.file4 = file4
      }
      this.casnoBlur()
    },
    uploadFile(val, index) {
      let obj = {
        name: val[0].name,
        file_type: index,
        size: (val[0].size / 1024).toFixed(2),
        format: getFileType(val[0].name)
      }
      this.form[`file${index}`] = Object.assign({}, obj)
      this.$refs[`uploadComponents${index}`].uploadFileToOss()
    },
    uploadCompleteOSS(val, index) {
      this.form[`file${index}`].url = val.url
    },
    handleFile(url) {
      window.open(url)
    },
    handleSubmit() {
      if (this.commodityText == '请上传文件' && this.form.file4 == null) {
        return this.$message.warning('请上传商检报告')
      }
      this.$refs["form"].validate(async (valid) => {
        if (valid) {
          let files_info = [this.form.file1]
          if (this.form.file2 != null) {
            files_info.push(this.form.file2)
          }
          if (this.form.file3 != null) {
            files_info.push(this.form.file3)
          }
          if (this.form.file4 != null) {
            files_info.push(this.form.file4)
          }
          let parmas = {
            id: this.$route.query.id,
            entrust_type: 1,
            user_id: this.USER_INFO.id,
            company_id: this.USER_INFO.company_id,
            entrust_kind: 2,
            cas_no: this.form.cas_no,
            is_big: this.form.is_big,
            into_storage_fee_way: this.form.into_storage_fee_way,
            quotation_no: this.form.quotation_no,
            files_info: JSON.stringify(files_info)
          }
          let res = await this.$store.dispatch("API_bookingSpace/uploadEntrustSave", parmas);
          this.submitLoading = true
          if (res.success) {
            this.submitLoading = false
            this.$router.push({
              path: '/bookingSpaceTitle',
              query: {
                id: res.data,
                type: 1,
                status: 0,
                pageType: 2
              }
            })
          }
        }
      });
    },
    handleDtatBank(val) {
      if (this.status == 3 || this.status == 4 || this.status == 1) {return}
      this.dataBankType = val
      this.dataBankDialog = true
    },
    // 在资料库选择后
    uploadDataBank(row, type) {
      this.form[`file${type}`] = Object.assign({}, row)
      this.dataBankDialog = false
    },
    //  关闭资料库
    closeUploadDataBank() {
      this.dataBankDialog = false
    },
    // 报价编号失去焦点
    async casnoBlur() {
      if (this.form.cas_no) {
        let parmas = {cas_no: this.form.cas_no}
        let res = await this.$store.dispatch('API_bookingSpace/getBookingCasInfo', parmas);
        if (res.success && res.data.length > 0) {
          this.commodityText = '请上传文件'
        } else {
          this.commodityText = '可不提供商检报告'
        }
      } else {
        this.commodityText = '输入CAS NO.信息后可知是否需上传'
      }
    },
    // 在线提交
    async saveFn() {
      if (this.commodityText == '请上传文件' && this.form.file4 == null) {
        this.$message.warning('请上传商检报告')
        return false
      }
      return new Promise((resolve, reject) => {
        this.$refs["form"].validate((valid) => {
          if (valid) {
            let files_info = []
            if (this.form.file2 != null) {
              files_info.push(this.form.file2)
            }
            if (this.form.file3 != null) {
              files_info.push(this.form.file3)
            }
            if (this.form.file4 != null) {
              files_info.push(this.form.file4)
            }
            let parmas = {
              entrust_type: 1,
              user_id: this.USER_INFO.id,
              company_id: this.USER_INFO.company_id,
              entrust_kind: 2,
              cas_no: this.form.cas_no,
              is_big: this.form.is_big,
              into_storage_fee_way: this.form.into_storage_fee_way,
              quotation_no: this.form.quotation_no,
              files_info: JSON.stringify(files_info)
            }
            resolve(parmas)
          } else {
            resolve(false)
          }
        });
      })
    },
    // 取消委托
    handleCancel() {
      this.$confirm('是否确定取消委托！', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        let parmas = {
          id: this.$route.query.id,
          status: 3
        }
        let res = await this.$store.dispatch("API_bookingSpace/uploadEntrustSave", parmas);
        if (res.success) {
          this.$message.success('操作成功')
          this.$router.push('/console/myEntrust/list')
        }
      }).catch(() => {
      });
    },
    // 重新委托
    handleAgainEntrust() {
      this.$router.push({
        path: '/bookingSpace',
        query: {
          detail: this.detail,
          activeName: 'first',
        }
      })
    },
    handleHelp() {
      window.open('https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/ymp/casno.jpg')
    }
  },
  watch: {
    detail: {
      deep: true,
      handler: function (newV, oldV) {
        this.initInfo()
      }
    }
  }
}
</script>

<style scoped lang="less">
.uploadBox {
  display: flex;
  align-items: center;

  .fileName {
    width: 300px;
  }

  .uploadBtn {
    color: #415058;
    width: 80px;
    height: 32px;
    background: #FFFFFF;
    border-radius: 4px;
    border: 1px solid #D9D9D9;
    line-height: 32px;
  }

  .uploadBtn2 {
    color: #415058;
    width: 94px;
    height: 32px;
    background: #F0F2F5;
    border-radius: 4px;
    border: 1px solid #D9D9D9;
    line-height: 32px;
    text-align: center;
    margin-left: 12px;
    cursor: pointer;
  }
}

.CSNO {
  padding-left: 100px;
  font-weight: 400;
  color: #298DF8;
}

.color298DF8 {
  color: #298DF8;
}

.color606266 {
  color: #606266;
}

.typeSelect {
  width: 250px;
}

/deep/ .el-form-item__label {
  color: #1F292E;
}

.submitBtn {
  background-color: #91BE42;
  border-color: #91BE42;
}
</style>