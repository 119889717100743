<template>
  <div>
    <div class="search">
      <el-input style="width: 300px" v-model="keyWords" size="small" placeholder="名称/地址信息"></el-input>
      <el-button size="small" class="submitBtn" type="success" @click="getDataList">查询</el-button>
    </div>
    <el-table
        :data="dataList"
        border
        highlight-current-row
        @current-change="handleCurrentChange"
        size="mini"
        style="width: 100%;height: 500px"
        :loading="loading"
    >
      <el-table-column prop="name" label="姓名" width="180" :show-overflow-tooltip="true">
        <template slot-scope="scope">
          {{ scope.row.name | textFormat }}
        </template>
      </el-table-column>
      <el-table-column prop="format" label="地址" width="180" :show-overflow-tooltip="true">
        <template slot-scope="scope">
          {{ scope.row.address | textFormat }}
        </template>
      </el-table-column>
      <el-table-column prop="size" label="电话传真" :show-overflow-tooltip="true">
        <template slot-scope="scope">
          {{ scope.row.telephone | textFormat }}
        </template>
      </el-table-column>
      <el-table-column prop="address" label="邮箱" :show-overflow-tooltip="true">
        <template slot-scope="scope">
          {{ scope.row.email | textFormat }}
        </template>
      </el-table-column>
    </el-table>
    <div class="btns">
      <el-button size="small" @click="handleClose">取消</el-button>
      <el-button type="success" class="submitBtn" size="small" @click="handleSubmit">确定</el-button>
    </div>
  </div>

</template>

<script>
export default {
  name: "adressDialog",
  props: {
    adressIndex: {
      type: String | Number,
      default: ''
    }
  },
  data() {
    return {
      dataList: [],
      nopage: 1,
      currentRow: null,
      loading: false,
      keyWords: ''
    }
  },
  filters: {
  },
  mounted() {
    this.getDataList()
  },
  methods: {
    async getDataList() {
      let address_type = ''
      if (this.adressIndex == 1) {
        address_type = 'shipper'
      }
      if (this.adressIndex == 2) {
        address_type = 'consignee'
      }
      if (this.adressIndex == 3) {
        address_type = 'notify_party'
      }
      let parmas = {
        user_id: this.USER_INFO.id,
        // address_type,
        name_or_address: this.keyWords
      }
      this.loading = true
      let res = await this.$store.dispatch("API_bookingSpace/getBookingLinkmanAddress", parmas);
      this.loading = false
      if (res.success) {
        this.dataList = res.data
      }
    },
    handleCurrentChange(row) {
      this.currentRow = Object.assign({}, row)
    },
    handleSubmit() {
      if (this.currentRow !== null) {
        this.$emit('selectAdress', this.currentRow)
      } else {
        this.$message.warning('请选择地址')
      }
    },
    handleClose() {
      this.$emit('closeAdress')
    }
  }
}
</script>

<style scoped lang="less">
.search {
  display: flex;
  justify-content: space-between;
  padding-bottom: 20px;
}
/deep/ .el-table__body tr.current-row > td {
  background-color: rgb(145, 190, 66) !important;
  div {
    color: #fff;
  }
}
.btns {
  margin-top: 20px;
  text-align: right;
}
.submitBtn {
  background-color: #91BE42;
  border-color: #91BE42;
}
</style>